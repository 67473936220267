<template>
	<div class="background">
		<canvas ref="canvas"></canvas>
	</div>
</template>

<script>
	import main from './bg.js'
	
	export default {
		mounted() {
			main(this.$refs.canvas);
		}
	}
</script>

<style lang="stylus" scoped>
	.background {
		position: relative;
		width: 100vw;
		height: 100vh;
		background: linear-gradient(-45deg, #3D7FFF, #00BDFF);
		
		canvas {
			position: absolute;
			width: 100vw;
			height: 100vh;
			opacity: 0.6;
		}
	}
</style>