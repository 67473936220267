<template>
	<div>
		<bg></bg>
		<div class="main flex-column flex-center">
			<div class="head flex-center-cross">
				<img :src="systemConfig.system_login_logo" alt="">
			</div>
			<div class="login">
				<h1 class="title">欢迎登录 — 生产管理系统</h1>
				<c-form ref="login" class="form">
					<c-input class="item" type="text" name="user_name" placeholder="请输入用户名" :clear="false" @enter="login">
						<template #prependIcon>
							<i class="item-icon tf tf-username tf-fw"></i>
						</template>
					</c-input>
					<c-input class="item" type="password" name="user_pwd" placeholder="请输入密码" :clear="false" @enter="login">
						<template #prependIcon>
							<i class="item-icon tf tf-password tf-fw"></i>
						</template>
					</c-input>
				</c-form>
				<c-button class="button" size="form" color="main" @click="login">登录</c-button>
				<div class="handle flex-between-center">
					<a></a>
					<a @click="forgetPassword">忘记密码?</a>
				</div>
			</div>
			<p class="copyright">{{systemConfig.system_copyright}}</p>
		</div>
	</div>
</template>

<script>
	import bg from './bg.vue'
	import {cryptoJs} from '@deanwu/vue-component-library'
	import {mapState} from 'vuex'
	
	export default {
		components: {
			bg
		},
		
		computed: {
			...mapState(['systemConfig']),
		},
		
		methods: {
			login() {
				this.$refs.login.submit({
					url: '/sys_user/login',
					rule: {
						user_name: [{
							type: 'empty',
							message: '请输入用户名'
						}],
						user_pwd: [{
							type: 'empty',
							message: '请输入密码'
						}]
					},
					dataFormatter: data => {
						data.user_pwd = cryptoJs.MD5(data.user_pwd).toString();
						return data;
					},
					success: (data, res) => {
						this.$store.commit('setSession', res.user_info.session);
						this.$store.commit('setUserInfo', res.user_info);
						this.$store.commit('setNav', res.user_menu_list || []);
						this.$store.commit('setAuth', Array.from(res.user_menu_key_list, item => item.menu_unique_key));
						this.navigateTo('/');
					}
				});
			},
			
			forgetPassword() {
				this.$confirm({
					type: 'message',
					message: '请联系管理员',
					buttonName: '我知道了',
					cancelButton: false
				})
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.main {
		position: fixed;
		left: 50%;
		top: 0;
		width: 380px;
		height: 100%;
		margin-left: -190px;
		
		.head {
			margin-bottom: 26px;
			
			img {
				height: 64px;
			}
		}
		
		.login {
			box-sizing: border-box;
			width: 100%;
			height: 340px;
			padding: 0 30px;
			overflow: hidden;
			background: #fff;
			
			.title {
				height: 92px;
				line-height: 102px;
				font-size: 22px;
				text-align: center;
			}
			
			.form {
				padding: 0;
				
				.item {
					height: 40px;
					margin-bottom: 12px;
					
					&-icon {
						margin: 0 10px 0 12px;
						font-size: 14px;
						color: #333;
					}
				}
			}
			
			.button {
				width: 100%;
				height: 46px;
				margin: 6px 0 26px;
				font-size: 18px;
			}
			
			.handle {
				a {
					font-size: 13px;
					color: #0060EF;
				}
			}
		}
		
		.copyright {
			margin-top: 25px;
			font-size: 12px;
			color: #FEFEFE;
			opacity: .7;
		}
	}
</style>
